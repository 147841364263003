import currencyFormatter from 'currency-formatter';
import { calculatePayoutOfferBooking } from '../components/bookingDetail/bookFunction/bookingInfo';

export const convertDataBookingForSupplier = ({
  data,
  paramState,
  paramProps,
}) => {
  if (!data) return {};
  const { selectedTab } = paramState;
  const { commonData, auth } = paramProps;

  let newData = {};

  // convert data prices
  let dataFare = data?.request?.estimate?.fare?.etaFare || 0;
  if (commonData?.companies[0]?._id) {
    dataFare = data.request.estimate.fare.totalPayout;
  }

  let checkCalculateSup =
    (selectedTab == 3 || selectedTab == 0) &&
    data.request?.estimate?.fare.supplierEarningType == 'default';

  const calculatorCurrency = (value) =>
    currencyFormatter.format(parseFloat(value || 0).toFixed(2), {
      symbol: auth?.selectedFleet?.currencies[0]?.iso,
    });

  if (checkCalculateSup) {
    newData.dataFare = calculatorCurrency(
      calculatePayoutOfferBooking(
        data?.request?.estimate?.fare,
        data?.request?.isShuttle ? 'shuttle' : 'transport',
        commonData?.companies[0],
        auth?.selectedFleet
      )
    );
  } else {
    newData.dataFare = calculatorCurrency(dataFare);
  }

  if (!auth?.user?.roles?.supplierPermission?.pricing) {
    newData.dataFare = '';
  }

  // convert another data

  return newData;
};
