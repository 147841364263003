import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import {
  Row,
  Col,
  FormGroup,
  Form,
  FormControl,
  Button,
  Modal,
} from 'react-bootstrap';
import Confirm from '../../components/confirm/Confirm';
import RadioButton from '../../components/radioButton/radio';
import { connect } from 'react-redux';
import * as customerActions from '../../actions/customerAction';
import * as corporateActions from '../../actions/corporateAction';
import * as settingActions from '../../actions/settingActions';
import * as loadingBarActions from '../../actions/loadingBarActions';
import { bindActionCreators } from 'redux';
import { Translate, I18n } from 'react-redux-i18n';
import moment from 'moment';
import Autosuggest from 'react-autosuggest';
import DateTime from '../../components/dateTime/DateTime';
import CCDropDown from '../../components/dropdown/ccDropDown';
import { PAYMENT_LINK_NAME } from '../../constants/commondata';
import { convertFormat24h } from '../../utils/commonFunctions';
import BookingPopup from './BookingPopup';
import './invoice.scss';
import _, { set } from 'lodash';
import CloseIcon from '@icons/material/CloseIcon';
import DotsVerticalIcon from '@icons/material/DotsVerticalIcon';
import currencyFormatter from 'currency-formatter';
import EmailPopup from './EmailPopup';

const START_DATE = moment.utc('2023-10-31T16:00Z', 'YYYY-MM-DD[T]HH:mm[Z]'); // fix bug on mac and ios

const getInfoFromSuggestion = (billingType, suggestion) => {
  const isCorporate = billingType === 'corporate';
  return {
    name: isCorporate
      ? `${suggestion?.companyInfo?.name} - ${
          suggestion?.adminAccount?.firstName || ''
        } ${suggestion?.adminAccount?.lastName || ''}`
      : suggestion?.fullName,
    nameCompanyCorp: suggestion?.companyInfo?.name,
    nameAdminCorp: `${suggestion?.adminAccount?.firstName} ${suggestion?.adminAccount?.lastName}`,
    email: isCorporate
      ? suggestion?.companyInfo?.invoiceEmail
      : suggestion?.email,
    phone: isCorporate ? suggestion?.adminAccount?.phone : suggestion?.phone,
    billingAddress: isCorporate ? suggestion?.companyInfo?.address : '',
  };
};

const CreateInvoiceForm = (
  { invoiceDetail, ACTION_ITEMS, hasPermissionAction, ...props },
  context
) => {
  const [billingType, setBillingType] = useState('corporate');
  const [billingInfo, setBillingInfo] = useState();
  const [billingSuggestions, setBillingSuggestions] = useState([]);
  const [textSearchBilling, setTextSearchBilling] = useState('');
  const [memo, setMemo] = useState('');
  const [dueDate, setDueDate] = useState('');
  const [showBookingPopup, setShowBookingPopup] = useState(false);
  const [showChargeCustomerModal, setShowChargeCustomerModal] = useState(false);
  const [showEmailInvoiceModal, setShowEmailInvoiceModal] = useState(false);

  const [bookingList, setBookingList] = useState([]);
  const [isSaving, setIsSaving] = useState(false);
  const [selectedBookings, setSelectedBookings] = useState([]);
  const [customerCreditInfo, setCustomerCreditInfo] = useState([]);
  const [isEditInvoice, setIsEditInvoice] = useState(false);

  const [confirm, setConfirm] = useState();

  const [invoiceDetailState, setInvoiceDetailState] = useState({
    fromDate: '',
    toDate: '',
  });

  const [chargeData, setChargeData] = useState({
    paymentMethod: 'default',
    note: '',
  });

  useEffect(() => {
    if (!_.isEmpty(invoiceDetail)) {
      setIsEditInvoice(true);
      setInvoiceDetailState({
        ...invoiceDetail,
        billingInfo: {
          _id: invoiceDetail.customerId,
        },
        fromDate: invoiceDetail.fromTime,
        toDate: invoiceDetail.toTime,
      });
      setMemo(invoiceDetail.memo);
      setBillingInfo({ _id: invoiceDetail.customerId });
      setBillingType(
        invoiceDetail.invoiceType === 1 ? 'corporate' : 'individual'
      );
      if (invoiceDetail.invoiceType === 1) {
        props.corporateActions
          .detailCorporate({
            _id: invoiceDetail.customerId,
            fleetId: props.auth.selectedFleet.fleetId,
          })
          .then((result) => {
            if (result?.res?.credits?.length > 0) {
              setCustomerCreditInfo(result?.res?.credits);
            }
          });
      } else {
        props.customerActions
          .findOneCustomer({
            userId: invoiceDetail.customerId,
            fleetId: props.auth.selectedFleet.fleetId,
          })
          .then((result) => {
            if (result?.res?.credits?.length > 0) {
              setCustomerCreditInfo(result?.res?.credits);
            }
          });
      }
    }
  }, []);

  useEffect(() => {
    handleOverviewInvoice();
  }, [
    invoiceDetailState.fromDate,
    invoiceDetailState.toDate,
    billingInfo,
    billingType,
    selectedBookings,
  ]);

  const handleDueDateChange = (e) => {
    setDueDate(e._d);
  };

  const handleFromDateChange = (e) => {
    setInvoiceDetailState({
      ...invoiceDetailState,
      fromDate: e._d,
    });
  };

  const handleEndDateChange = (e) => {
    // setToDate(e._d)
    setInvoiceDetailState({
      ...invoiceDetailState,
      toDate: e._d,
    });
  };

  const isFilteredDayValid = (current) => {
    if (invoiceDetailState.toDate) {
      return (
        current.isBetween(
          moment(invoiceDetailState.toDate).subtract(31, 'day'),
          invoiceDetailState.toDate
        ) &&
        !current.isAfter(moment()) &&
        current.isAfter(START_DATE)
      );
    }
    return !current.isAfter(moment()) && current.isAfter(START_DATE);
  };

  const isFilteredToDayValid = (current) => {
    if (invoiceDetailState.fromDate) {
      return (
        current.isBetween(
          invoiceDetailState.fromDate - 1,
          moment(invoiceDetailState.fromDate).add(31, 'day')
        ) &&
        !current.isAfter(moment()) &&
        current.isAfter(START_DATE)
      );
    }

    return !current.isAfter(moment()) && current.isAfter(START_DATE);
  };

  const handleChangeTravelerType = (e) => {
    setBillingInfo();
    setDueDate('');
    setSelectedBookings([]);
    setBillingSuggestions([]);
    setTextSearchBilling('');
    setInvoiceDetailState({
      fromDate: '',
      toDate: '',
    });
    setBillingType(e.target.value);
  };

  const handleOverviewInvoice = () => {
    if (invoiceDetailState.status === 2) return;
    if (
      billingType &&
      invoiceDetailState.fromDate &&
      invoiceDetailState.toDate &&
      billingInfo?._id
    ) {
      const param = {
        id: billingInfo._id,
        invoiceType: isEditInvoice
          ? invoiceDetailState.invoiceType
          : billingType === 'corporate'
          ? 1
          : 0,
        timezone: props.auth.selectedFleet.timezone,
        fromDate: moment(invoiceDetailState.fromDate).format('YYYY-MM-DD'),
        toDate: moment(invoiceDetailState.toDate).format('YYYY-MM-DD'),
      };

      if (isEditInvoice && invoiceDetailState.invoiceId) {
        param.invoiceId = invoiceDetailState.invoiceId;
      } else if (selectedBookings?.length > 0) {
        param.selectedBookings = selectedBookings;
      }

      props.settingActions.overviewInvoice(param).then((result) => {
        if (!_.isEmpty(result)) {
          setInvoiceDetailState({
            ...invoiceDetailState,
            amount: result.amount,
            paidAmount: result.paidAmount,
            total: result.total,
            numOfItems: result.totalItems,
          });
        }
      });
    }
  };
  const getBillingSuggestions = (dataInputChange, type) => {
    if (!dataInputChange?.value) return setBillingSuggestions([]);
    let param = {
      limit: 50,
      page: 0,
    };
    if (type === 'individual') {
      param.query = {
        fleetId: props.auth.selectedFleet.fleetId,
        fullNameSort: 'fullNameSort',
        str: dataInputChange?.value,
      };
      props.customerActions.getCustomer(param).then((data) => {
        if (data.ok && data?.res?.list?.length > 0) {
          setBillingSuggestions(data.res.list);
        } else {
          setBillingSuggestions([]);
        }
      });
    } else {
      param.query = {
        fleetId: props.auth.selectedFleet.fleetId,
        str: dataInputChange?.value,
      };
      props.corporateActions.getCorporate(param).then((data) => {
        if (data.ok && data?.res?.list?.length > 0) {
          setBillingSuggestions(data.res.list);
        } else {
          setBillingSuggestions([]);
        }
      });
    }
  };

  const debounceGetBillingSuggestions = (dataInputChange) => {
    debounceQuery(dataInputChange, billingType);
  };

  const debounceQuery = useCallback(
    _.debounce(
      (dataInputChange, billingType) =>
        getBillingSuggestions(dataInputChange, billingType),
      400
    ),
    []
  );

  const resetBillingSuggestions = () => {
    setBillingSuggestions([]);
  };

  const getBillingSuggestionValue = (suggestion) => {
    return getInfoFromSuggestion(billingType, suggestion).name;
  };

  const billingAutocompleteSlectedHandle = (event, { suggestion }) => {
    setBillingInfo(suggestion);
  };

  const renderBillingSuggestion = (suggestion, { query }) => {
    const data = getInfoFromSuggestion(billingType, suggestion);
    return (
      <div className="contactSuggestion">
        <span>{data.name}</span>
        <span>{`
          ${data.email ? data.email : ''} 
          ${data.email && data.phone ? ', ' : ''} 
          ${data.phone ? data.phone : ''}`}</span>
      </div>
    );
  };

  const handleBillingChanged = (event, { newValue }) => {
    setTextSearchBilling(newValue);
  };

  const renderBillingContact = () => {
    if (isEditInvoice) {
      return (
        <>
          <p>{I18n.t('invoice.billingContact')}</p>
          <div className="billingContact">
            <p>{invoiceDetailState.billingName}</p>
            <p>
              {invoiceDetailState.invoiceType === 1 &&
                `${invoiceDetailState.customerName}\n`}
              {invoiceDetailState.billingAddress &&
                `${invoiceDetailState.billingAddress}\n`}
              {invoiceDetailState.billingEmail &&
                `${invoiceDetailState.billingEmail}\n`}
              {invoiceDetailState.billingPhone &&
                `${invoiceDetailState.billingPhone}`}
            </p>
          </div>
        </>
      );
    }
    return (
      <>
        <p>
          {I18n.t('invoice.billingContact')}
          <span className="require"> *</span>
        </p>
        <FormGroup>
          <FormGroup className="radio-button-group">
            <RadioButton
              text={I18n.t('newbooking.Individual')}
              value="individual"
              onChange={handleChangeTravelerType}
              name="billingTypeRadio"
              id="individual"
              checked={billingType === 'individual'}
              disable={false}
            />
            <RadioButton
              text={I18n.t('newbooking.Corporation')}
              value="corporate"
              onChange={handleChangeTravelerType}
              name="billingTypeRadio"
              id="corporate"
              checked={billingType === 'corporate'}
              disable={false}
            />
          </FormGroup>
        </FormGroup>
        <FormGroup
          className="assign-driver-container"
        >
          <Autosuggest
            suggestions={billingSuggestions}
            onSuggestionsFetchRequested={debounceGetBillingSuggestions}
            onSuggestionsClearRequested={resetBillingSuggestions}
            getSuggestionValue={getBillingSuggestionValue}
            renderSuggestion={renderBillingSuggestion}
            onSuggestionSelected={billingAutocompleteSlectedHandle}
            // shouldRenderSuggestions={(value, reason) => value?.trim().length > 1}
            inputProps={{
              className: 'form-control form-custom',
              value: textSearchBilling,
              placeholder: 'Search for client',
              onChange: handleBillingChanged,
            }}
          />
        </FormGroup>
      </>
    );
  };

  const renderItems = () => {
    const handleViewEdit = () => {
      setShowBookingPopup(true);
    };
    const currencyIso =
      invoiceDetailState.currencyIso ||
      props.auth.selectedFleet.currencyISOValidation;
    const dataItems = [
      {
        title: 'Items',
        value: invoiceDetailState.numOfItems,
      },
      {
        title: 'Amount Due',
        value: currencyFormatter.format(
          invoiceDetailState.amount - invoiceDetailState.paidAmount || 0,
          {
            code: currencyIso,
          }
        ),
        bold: true,
      },
      {
        title: 'Total Amount',
        value: currencyFormatter.format(invoiceDetailState.amount || 0, {
          code: currencyIso,
        }),
      },
    ];
    return (
      <>
        <p className="itemsTitle">
          <span>{I18n.t('invoice.ITEMS')}</span>
          {hasPermissionAction && (
            <Button
              className="btn-header text-add-header"
              onClick={handleViewEdit}
              disabled={
                !billingInfo?._id ||
                !invoiceDetailState.fromDate ||
                !invoiceDetailState.toDate
              }
            >
              {invoiceDetailState.status === 2 ? (
                <Translate value="invoice.View" />
              ) : (
                <Translate value="invoice.viewEdit" />
              )}
            </Button>
          )}
        </p>
        <div className="itemDetail">
          {dataItems.map((item) => (
            <p className={item.bold ? 'textActive' : ''}>
              <span>{item.title}</span>
              <span>{item.value}</span>
            </p>
          ))}
        </div>
      </>
    );
  };

  const renderPaymentInfo = () => {
    if (invoiceDetailState.status !== 2) return null;
    const currencyIso =
      invoiceDetailState.currencyIso ||
      props.auth.selectedFleet.currencyISOValidation;
    let dataItems = [
      {
        title: 'Paid On',
        value: moment(invoiceDetailState.paidTime).format(
          `MMMM DD, YYYY ${convertFormat24h(
            props.auth.selectedFleet.format24Hour
          )}`
        ),
      },
      {
        title: 'Payment Method',
        value:
          invoiceDetailState.paymentMethod === 'credit'
            ? `Card: ${
                invoiceDetailState.cardType
              } *${invoiceDetailState?.cardMasked?.slice(-4)}`
            : PAYMENT_LINK_NAME[invoiceDetailState.paymentMethod],
      },
      {
        title: 'Transaction ID',
        value: invoiceDetailState.transactionId,
      },
      {
        title: 'Amount Paid',
        bold: true,
        value: currencyFormatter.format(invoiceDetailState.invoiceAmount || 0, {
          code: currencyIso,
        }),
      },
    ];
    if (invoiceDetailState.chargeNote) {
      dataItems.push({
        title: invoiceDetailState.chargeNote,
        color: '#667085',
      });
    }
    if (invoiceDetailState.paymentMethod !== 'credit') {
      dataItems = dataItems.filter((ob) => ob.title !== 'Transaction ID');
    }
    return (
      <>
        <p className="itemsTitle">
          <span>{I18n.t('invoice.Payments')}</span>
        </p>
        <div className="itemDetail">
          {dataItems.map((item) => (
            <p className={item.bold ? 'textActive' : ''}>
              <span style={{ color: item.color }}>{item.title}</span>
              <span>{item.value}</span>
            </p>
          ))}
        </div>
      </>
    );
  };

  const renderInvoicePeriod = () => {
    return (
      <>
        <p>
          {I18n.t('invoice.invoicePeriod')}
          <span className="require"> *</span>
        </p>
        <Row>
          <Col xs={12} sm={6}>
            <DateTime
              value={moment(invoiceDetailState.fromDate)?.locale(
                props.language
              )}
              timeFormat={false}
              onChange={handleFromDateChange}
              inputProps={{
                readOnly: true,
                className: 'date-readonly form-control fromDateInvoice',
                placeholder: I18n.t('quest_event.start_date'),
                disabled: isEditInvoice,
                value: invoiceDetailState.fromDate
                  ? moment(invoiceDetailState.fromDate)
                      ?.locale(props.language)
                      ?.format('L')
                  : '',
              }}
              isValidDate={isFilteredDayValid}
              closeOnSelect
            />
          </Col>
          <Col xs={12} sm={6}>
            <DateTime
              value={moment(invoiceDetailState.toDate)?.locale(props.language)}
              timeFormat={false}
              onChange={handleEndDateChange}
              inputProps={{
                readOnly: true,
                className: 'date-readonly form-control',
                placeholder: I18n.t('quest_event.end_date'),
                disabled: isEditInvoice,
                value: invoiceDetailState.toDate
                  ? moment(invoiceDetailState.toDate)
                      ?.locale(props.language)
                      ?.format('L')
                  : '',
              }}
              closeOnSelect
              isValidDate={isFilteredToDayValid}
            />
          </Col>
        </Row>
      </>
    );
  };

  const handleActionsInvoice = (key) => {
    const { ACTIONS_TYPE } = props;
    switch (key) {
      case ACTIONS_TYPE.deleteInvoice:
        setConfirm({
          id: ACTIONS_TYPE.deleteInvoice,
          title: I18n.t('invoice.Delete'),
          body: I18n.t('invoice.Delete_content'),
          buttonTitle: 'Delete',
          closeButtonText: 'Cancel',
          invoiceId: invoiceDetailState.invoiceId,
        });
        break;
      case ACTIONS_TYPE.downloadPDF:
        if (invoiceDetailState.pdfFile) {
          window.open(invoiceDetailState.pdfFile, 'DOWNLOAD');
        }
        break;
      case ACTIONS_TYPE.paymentLink:
        props.openPaymentLink(invoiceDetailState);
        break;
      case ACTIONS_TYPE.copyInvoice:
        props.copyInvoice(invoiceDetailState);
        break;
      case ACTIONS_TYPE.sendInvoice:
        setShowEmailInvoiceModal(true);
        break;

      default:
        break;
    }
  };

  const handleResetAndClose = (hasReset = false) => {
    setBillingType('corporate');
    setIsSaving(false);
    setBillingInfo({});
    setMemo('');
    setDueDate('');
    setBookingList([]);
    setSelectedBookings([]);
    setIsEditInvoice(false);
    setInvoiceDetailState({});
    setCustomerCreditInfo([]);
    props.handleClose(hasReset);
  };

  const renderTitle = () => {
    const handleActionsSelected = (key) => {
      alert(key);
    };
    const actionsCustom = _.remove(
      [...ACTION_ITEMS],
      (obj) => !['editInvoice', 'viewInvoice'].includes(obj.eventKey)
    );
    return (
      <div className="titleCreate">
        <div>
          {isEditInvoice ? (
            <>
              <h3>{`Invoice No. ${invoiceDetailState.invoiceId}`}</h3>
              <p>
                {`Due ${moment(invoiceDetailState.dueTime).format(
                  'MMMM DD, YYYY'
                )}`}
                {isEditInvoice && (
                  <>
                    {invoiceDetailState.status === 0 && (
                      <>
                        <span className="agoDate pending">
                          {moment(invoiceDetailState.dueTime)
                            .add(1, 'day')
                            .fromNow()}
                        </span>
                      </>
                    )}
                    {invoiceDetailState.status === 1 && (
                      <>
                        <span className="agoDate pending">
                          {moment(invoiceDetailState.dueTime)
                            .add(1, 'day')
                            .fromNow()}
                        </span>
                      </>
                    )}
                    {invoiceDetailState.status === 2 && (
                      <span className="agoDate paid">
                        {I18n.t('invoice.Paid')}
                      </span>
                    )}
                    {/* {invoiceDetailState.status === 1 && (
                      <span className="agoDate pastDue">
                        {I18n.t('invoice.pastDue')}
                      </span>
                    )} */}
                  </>
                )}
              </p>
            </>
          ) : (
            <h3>{I18n.t('invoice.createInvoice')}</h3>
          )}
        </div>
        <div className="groupLeftBtn">
          <Button
            type="button"
            className="btn-header text-add-header"
            // aria-label="Close"
            onClick={handleResetAndClose}
          >
            <CloseIcon color={'white'} />
          </Button>
          {isEditInvoice && (
            <CCDropDown
              id="invoiceAction-dropdown"
              items={
                invoiceDetailState.status === 0
                  ? actionsCustom
                  : actionsCustom.filter((ob) => !ob.hideWhenPaided)
              }
              customTitle={<DotsVerticalIcon />}
              selectedItems={[]}
              valueKey="eventKey"
              labelKey="label"
              onSelect={handleActionsInvoice}
              className="text-active"
              // disabled={this.props.disabled}
            />
          )}
        </div>
      </div>
    );
  };

  const renderMemo = () => {
    // Edit invoice
    if (isEditInvoice) {
      if (memo) {
        const splitMemo = memo.split('\n');
        return (
          <FormGroup>
            <Form.Label>
              <Translate value={`invoice.memo`} />
            </Form.Label>
            <ul className="memoDetail">
              {splitMemo.map((mm, id) => (
                <li key={id}> {mm} </li>
              ))}
            </ul>
          </FormGroup>
        );
      }
      return;
    }

    // create invoice
    return (
      <FormGroup>
        <Form.Label>
          <Translate value={`invoice.memo`} />
        </Form.Label>
        <FormControl
          as="textarea"
          className={'form-custom'}
          value={memo}
          onChange={(e) => setMemo(e.target.value)}
          placeholder={I18n.t('invoice.memoMessage')}
          disabled={isEditInvoice}
          rows={5}
          maxLength={1000}
        />
      </FormGroup>
    );
  };
  const handleSelectPayment = (e) => {
    setChargeData({
      ...chargeData,
      paymentMethod: e.target.value,
    });
  };

  const formatAmount = (value) => {
    return currencyFormatter.format(value, {
      code:
        invoiceDetailState.currencyIso ||
        props.auth.selectedFleet.currencyISOValidation,
    });
  };

  const convertAmount = () => {
    return {
      amountDue: invoiceDetailState.amount - invoiceDetailState.paidAmount || 0,
      amount: invoiceDetailState.amount,
    };
  };

  const handleChargeConfirmed = () => {
    let paymentRequest = chargeData.paymentMethod,
      token = '';
    if (!['cash', 'check', 'wire', 'digitalWallet'].includes(paymentRequest)) {
      paymentRequest = 'credit';
      token = chargeData.paymentMethod;
    }
    const params = {
      fleetId: props.auth.selectedFleet.fleetId,
      invoiceId: invoiceDetailState.invoiceId,
      amount: convertAmount().amountDue,
      operatorId: props.auth?.user?._id,
      chargeNote: chargeData.note,
      paymentMethod: paymentRequest,
      token: token,
    };
    props.settingActions.chargeCustomerForInvoice(params).then((result) => {
      if (result.res.returnCode === 200) {
        context.notification('success', I18n.t('invoice.payCompleted'));
        handleResetAndClose(true);
      } else {
        context.notification('error', 'An error occurred during the payment');
      }
    });
  };

  const renderChargeCustomerModal = () => {
    let paymentMethod = [
      {
        type: 'cash',
        value: 'cash',
      },
      {
        type: 'check',
        value: 'check',
      },
      {
        type: 'wire',
        value: 'wire',
      },
      {
        type: 'digitalWallet',
        value: 'digitalWallet',
      },
    ];
    const amountDue = formatAmount(convertAmount().amountDue || 0);
    return (
      <Modal
        show={showChargeCustomerModal}
        bsSize={'xs'}
        backdrop={true}
        dialogClassName="confirm-dialog"
        className="ChargeCustome"
        onHide={() => {
          setShowChargeCustomerModal(false);
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title>charge customer</Modal.Title>
          <button
            type="button"
            className="close"
            aria-label="Close"
            onClick={() => {
              setShowChargeCustomerModal(false);
            }}
          >
            <span aria-hidden="true">×</span>
          </button>
        </Modal.Header>
        <Modal.Body>
          <div className="fareCharge">
            <p>
              <span>Before</span>
              <span>{amountDue}</span>
            </p>
            <p>
              <span>After</span>
              <span>$0</span>
            </p>
          </div>
          <FormGroup>
            <FormControl
              className={'form-custom'}
              type="text"
              value={amountDue}
              disabled={true}
            />
          </FormGroup>
          <FormGroup>
            <FormControl
              as="select"
              className="selectPayment"
              value={chargeData.paymentMethod}
              placeholder="Payment Method"
              onChange={handleSelectPayment}
            >
              <option value={'default'} key={'default'}>
                {I18n.t('cue.Payment_Method')}
              </option>
              {paymentMethod.map((pm) => {
                return (
                  <option value={pm.value} key={pm.type}>
                    {I18n.t(`invoice.${pm.type}`)}
                  </option>
                );
              })}
              {customerCreditInfo.map((card) => {
                return (
                  <option key={card.localToken} value={card.localToken}>
                    {`${card?.cardType?.toUpperCase()} *${card?.cardMask?.slice(
                      -4
                    )}`}
                  </option>
                );
              })}
            </FormControl>
          </FormGroup>
          <FormGroup>
            <FormControl
              type="text"
              className={'form-custom selectPayment'}
              value={chargeData.note}
              onChange={(e) =>
                setChargeData({ ...chargeData, note: e.target.value })
              }
              placeholder={'Add note'}
            />
          </FormGroup>
          <div className="btnGroup">
            <Button
              className="btn-header text-add-header"
              onClick={() => {
                setShowChargeCustomerModal(false);
              }}
            >
              <Translate value="invoice.Close" />
            </Button>
            <Button
              className="btn-save view-button"
              onClick={handleChargeConfirmed}
              disabled={chargeData.paymentMethod === 'default'}
            >
              {I18n.t('invoice.chargeBtn').format(amountDue)}
            </Button>
          </div>
        </Modal.Body>
      </Modal>
    );
  };

  const handleCloseEmailInvoiceModal = () => {
    setShowEmailInvoiceModal(false);
  };

  const doCreateInvoice = async (typeSave, emailInfo) => {
    const billingInfoConverted = getInfoFromSuggestion(
      billingType,
      billingInfo
    );
    let param = {
      id: billingInfo._id,
      fleetId: props.auth.selectedFleet.fleetId,
      invoiceType: billingType === 'individual' ? 0 : 1,
      fromDate: moment(invoiceDetailState.fromDate).format('YYYY-MM-DD'),
      toDate: moment(invoiceDetailState.toDate).format('YYYY-MM-DD'),
      timezone: props.auth.selectedFleet.timezone,
      memo: memo,
      dueDate: moment(dueDate).format('YYYY-MM-DD'),
      customerName:
        billingType === 'individual'
          ? billingInfoConverted.name
          : billingInfoConverted.nameAdminCorp,
      billingName:
        billingType === 'individual'
          ? billingInfoConverted.name
          : billingInfoConverted.nameCompanyCorp,
      billingPhone: billingInfoConverted.phone,
      billingEmail: billingInfoConverted.email,
      billingAddress: billingInfoConverted.billingAddress,
      bookingIds: selectedBookings || [],
    };

    if (typeSave === 'saveSendEmail') {
      // chưa có thông tin email thì mở popup
      if (!emailInfo) {
        setShowEmailInvoiceModal(true);
        return;
      } else {
        param.email = {
          to: emailInfo?.to.map((obj) => obj.id) || [],
          cc: emailInfo?.cc.map((obj) => obj.id) || [],
          content: emailInfo.content,
        };
      }
    }
    try {
      const resultSave = await props.settingActions.createInvoice(param);
      if (resultSave?.invoiceId) {
        setIsSaving(false);
        handleResetAndClose();
        props.resetInvoiceList();
        context.notification(
          'success',
          'This invoice has been create successfully!'
        );
      } else {
        setIsSaving(false);
        if (resultSave?.errorBookings) {
          const bkText = (
            resultSave?.errorBookings.map((bk) => bk.bookId) || []
          ).join(', ');
          context.notification(
            'error',
            `${resultSave.message}. (BookId: ${bkText})`
          );
        } else {
          context.notification(
            'error',
            resultSave.message || I18n.t(`General.ServerError`)
          );
        }
      }
    } catch (error) {}
  };

  const renderCreateInvoiceButton = () => {
    if (isEditInvoice) return null;
    const actionItems = [
      {
        value: 'saveSendEmail',
        title: 'invoice.saveSend',
      },
      {
        value: 'onlySave',
        title: 'invoice.onlySave',
      },
    ];
    const isDisable =
      !billingInfo?._id ||
      !invoiceDetailState ||
      !invoiceDetailState.fromDate ||
      !invoiceDetailState.toDate ||
      !invoiceDetailState.numOfItems > 0 ||
      !dueDate ||
      isSaving;
    return (
      <div className="createInvoiceBtn">
        <CCDropDown
          id="createInvoice-dropdown"
          items={actionItems}
          customTitle={<span> {I18n.t('invoice.saveSend')} </span>}
          selectedItems={[]}
          valueKey="value"
          labelKey="title"
          onSelect={doCreateInvoice}
          className="text-active"
          disabled={isDisable}
        />
      </div>
    );
  };
  const handleCloseBookingPopup = (resetAmount = false) => {
    setShowBookingPopup(false);
    if (resetAmount === true) handleOverviewInvoice();
  };

  const handleConfirmButtonClick = (id, confirm) => {
    switch (id) {
      case props.ACTIONS_TYPE.deleteInvoice: {
        props.deleteInvoice(invoiceDetailState.invoiceId).then((result) => {
          if (result) handleResetAndClose();
        });
        break;
      }
    }
  };

  const handleConfirmCloseClick = () => {
    setConfirm(null);
  };

  const onlySendEmail = (emailInfo) => {
    props.settingActions
      .sendMailInvoice({
        id: invoiceDetailState.invoiceId,
        to: emailInfo?.to?.map((obj) => obj.value) || [],
        cc: emailInfo?.cc?.map((obj) => obj.value) || [],
        content: emailInfo.content,
      })
      .then((result) => {
        if (result) {
          context.notification(
            'success',
            'This email has been send successfully!'
          );
          setShowEmailInvoiceModal(false);
        } else {
          context.notification('error', I18n.t(`General.ServerError`));
        }
      })
      .catch(() => {
        context.notification('error', I18n.t(`General.ServerError`));
      });
  };

  return (
    <>
      <Confirm
        confirm={confirm}
        className={'confirmInvoice'}
        handleConfirmButtonClick={handleConfirmButtonClick}
        handleConfirmCloseClick={handleConfirmCloseClick}
      />

      {
        // Email Invoice
        showEmailInvoiceModal && (
          <EmailPopup
            handleSaveConfirmed={
              isEditInvoice
                ? onlySendEmail // lúc send invoice ở button actions
                : (emailInfo) => doCreateInvoice('saveSendEmail', emailInfo) // lúc tạo
            }
            billingEmail={
              isEditInvoice
                ? invoiceDetailState.billingEmail
                : getInfoFromSuggestion(billingType, billingInfo).email
            }
            closePopup={handleCloseEmailInvoiceModal}
          />
        )
      }

      {/* booking list modal */}
      {showBookingPopup && (
        <BookingPopup
          handleClose={handleCloseBookingPopup}
          billingInfo={billingInfo}
          invoiceDetail={invoiceDetailState}
          isEditInvoice={isEditInvoice}
          billingType={billingType}
          fromDate={invoiceDetailState.fromDate}
          toDate={invoiceDetailState.toDate}
          bookingList={bookingList}
          selectedBookings={selectedBookings}
          setSelectedBookings={setSelectedBookings}
        />
      )}

      {
        // charge customer modal
        renderChargeCustomerModal()
      }

      {/* create invoice modal */}
      <Modal
        show={true}
        backdrop={true}
        dialogClassName="confirm-dialog"
        className="confirm createInvoice"
        onHide={handleResetAndClose}
      >
        <Modal.Body>
          {renderTitle()}
          {renderBillingContact()}
          {renderInvoicePeriod()}
          {renderItems()}
          {renderPaymentInfo()}
          {
            // charge customer button
            isEditInvoice && invoiceDetail.status !== 2 && (
              <FormGroup>
                <Form.Label>
                  <Translate value={`invoice.Payments`} />
                </Form.Label>
                <p>
                  {hasPermissionAction && (
                    <Button
                      className="btn-save view-button"
                      onClick={() => setShowChargeCustomerModal(true)}
                    >
                      <Translate value="invoice.chargeCustomer" />
                    </Button>
                  )}
                </p>
              </FormGroup>
            )
          }
          {renderMemo()}
          {!isEditInvoice && (
            <FormGroup>
              <Form.Label>
                <Translate value={`invoice.dueDate`} />
                <span className="require"> *</span>
              </Form.Label>
              <DateTime
                value={moment(dueDate)?.locale(props.language)}
                timeFormat={false}
                onChange={handleDueDateChange}
                inputProps={{
                  readOnly: true,
                  className: 'date-readonly form-control',
                  placeholder: 'MM/DD/YYYY',
                  value: dueDate
                    ? moment(dueDate)?.locale(props.language)?.format('L')
                    : '',
                }}
                closeOnSelect
                isValidDate={(current) => current.isAfter(moment())}
              />
            </FormGroup>
          )}
          {renderCreateInvoiceButton()}
        </Modal.Body>
      </Modal>
    </>
  );
};

CreateInvoiceForm.contextTypes = {
  notification: PropTypes.func,
};

function mapStateToProps(state) {
  return {
    commonData: state.commonData,
    auth: state.auth,
    permissions: state.menuHandle.modulePermission,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    settingActions: bindActionCreators(settingActions, dispatch),
    loadingBarActions: bindActionCreators(loadingBarActions, dispatch),
    corporateActions: bindActionCreators(corporateActions, dispatch),
    customerActions: bindActionCreators(customerActions, dispatch),
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(CreateInvoiceForm);
